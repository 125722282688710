<template>
  <el-card class="box-card" id="firstRow">
    <template #header>
      <div class="card-header">
        <span class="SubHead">昨日数据</span>
        <router-link to="/reportList">
          <!-- <span class="brandColor reportDetail">查看详情</span> -->
        </router-link>
      </div>
    </template>
    <el-scrollbar>
      <el-row :gutter="10" style="width: 100%;flex-wrap:nowrap;">
        <!-- 昨日数据表1 -->
        <el-col :span="6" style="min-width:290px;">
          <el-card shadow="never" class="neverCard">
            <div class="never-header">
              <span class="Text">昨日总消耗量</span>
              <el-tooltip class="item" effect="light" content="" placement="top">
                <template #content>
                  昨日总消耗量：<br />
                  昨日全部广告账户广告消耗量的综合<br />
                  日均消耗量：<br />
                  广告账户一周内消耗量平均值
                </template>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <p class="MainHead never-p">{{ $filters.thousands(expendTotal) }}</p>
            <el-row style="margin: 30px 0;">
              <el-col :span="12">
                <p class="never-p">
                  <span class="Text">周同比</span>
                  <i class="el-icon-caret-top" v-if="weekType == 1"></i>
                  <i class="el-icon-caret-bottom" v-else-if="weekType == 2"></i>
                  <i v-else></i>
                  {{ weekRatio }} <i v-show="weekType != 0">%</i>
                </p>
              </el-col>
              <el-col :span="12">
                <p class="never-p">
                  <span class="Text">日环比</span>
                  <i class="el-icon-caret-top" v-if="dayType == 1"></i>
                  <i class="el-icon-caret-bottom" v-else-if="dayType == 2"></i>
                  <i v-else></i>
                  {{ dayRatio }} <i v-show="dayType != 0">%</i>
                </p>
              </el-col>
            </el-row>
            <el-divider class="never-divider" />
            <span class="HelpText">日均消耗量：{{ $filters.thousands(expendDaily) }}</span>
          </el-card>
        </el-col>
        <!-- 昨日数据表2 -->
        <el-col :span="6" style="min-width:290px;">
          <el-card shadow="never" class="neverCard">
            <div class="never-header">
              <span class="Text">CPM</span>
              <el-tooltip class="item" effect="light" content="" placement="top">
                <template #content>
                  CPM：<br />
                  平均每一千人看到广告一次一共需要的广告成本<br />
                  日均点击量：<br />
                  广告账户一周内点击量平均值
                </template>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <p class="MainHead never-p">{{ CPMNum }}</p>
            <!-- CPM图表 -->
            <div id="CPMTT" style="width: 100%;height:60px;margin-bottom: 30px;"></div>
            <el-divider class="never-divider" />
            <span class="HelpText">日均点击量：{{ $filters.thousands(ClicksDaily) }}</span>
          </el-card>
        </el-col>
        <!-- 昨日数据表3 -->
        <el-col :span="6" style="min-width:290px;">
          <el-card shadow="never" class="neverCard">
            <div class="never-header">
              <span class="Text">CTR</span>
              <el-tooltip class="item" effect="light" content="" placement="top">
                <template #content>
                  CTR：<br />
                  广告的实际点击次数除以广告的展现量
                </template>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <p class="MainHead never-p">{{ CTRNum }}%</p>
            <el-progress
              :text-inside="true"
              color="#2b82ff"
              :stroke-width="30"
              :percentage="CTRNum * 10"
              style="margin: 35px 0 8px;"
            >
              <span>{{ CTRNum }}%</span>
            </el-progress>
            <div class="progressRange">
              <span class="Text">0</span>
              <span class="Text">10%</span>
            </div>
            <el-divider class="never-divider" />
            <el-row>
              <el-col :span="12">
                <p class="never-bp HelpText">
                  <span>周同比</span>
                  <i class="el-icon-caret-top" v-if="CTRWeekType == 1"></i>
                  <i class="el-icon-caret-bottom" v-else-if="CTRWeekType == 2"></i>
                  <i v-else></i>
                  {{ CTRWeekRatio }} <i v-show="CTRWeekType != 0">%</i>
                </p>
              </el-col>
              <el-col :span="12">
                <p class="never-bp HelpText">
                  <span>日环比</span>
                  <i class="el-icon-caret-top" v-if="CTRDayType == 1"></i>
                  <i class="el-icon-caret-bottom" v-else-if="CTRDayType == 2"></i>
                  <i v-else></i>
                  {{ CTRDayRatio }} <i v-show="CTRDayType != 0">%</i>
                </p>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <!-- 昨日数据表4 -->
        <el-col :span="6" style="min-width:290px;">
          <el-card shadow="never" class="neverCard">
            <div class="never-header">
              <span class="Text">转化率</span>
              <el-tooltip class="item" effect="light" content="" placement="top">
                <template #content>
                  转化率：<br />
                  以百分比形式表示每次广告互动带来的平均转化次数。<br />
                </template>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <p class="MainHead never-p">{{ ROASNum }}</p>
            <!-- ROAS图表 -->
            <div id="ROASTT" style="width: 100%;height:60px;margin-bottom: 30px;"></div>
            <el-divider class="never-divider" />
            <span class="HelpText">
              <span>
                比平均水平
                <i v-if="ROASType == 1">高</i>
                <i v-else-if="ROASType == 2">低</i>
                <!-- <i v-else></i> -->
              </span>
              <i class="el-icon-caret-top" v-if="ROASType == 1"></i>
              <i class="el-icon-caret-bottom" v-else-if="ROASType == 2"></i>
              <!-- <i v-else></i> -->
              {{ ROASAverage }} <i v-show="ROASType != 0">%</i>
            </span>
          </el-card>
        </el-col>
      </el-row>
    </el-scrollbar>
  </el-card>

  <el-card class="box-card" id="secondRow">
    <template #header>
      <div class="card-header">
        <span class="SubHead">广告账户趋势</span>
        <div>
          <el-row :gutter="10">
            <el-select
              v-model="adTime"
              placeholder="请选择范围"
              size="small"
              @change="changeTrendChart"
              style="width:90px;margin-right: 10px;"
            >
              <el-option label="按天" value="1"></el-option>
              <el-option label="按周" value="2"></el-option>
              <el-option label="按月" value="3"></el-option>
            </el-select>
            <el-date-picker
              v-model="trendTimeRange"
              type="daterange"
              size="small"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="X"
              unlink-panels
              :shortcuts="shortcuts"
              :disabled-date="disabledDate"
              @calendar-change="calendarChange"
              @blur="changeTrendChart"
            ></el-date-picker>
          </el-row>
        </div>
      </div>
    </template>
    <el-row class="trendData">
      <el-col :span="18">
        <!-- cost图表 -->
        <div id="costTT" style="width: 100%;height:350px;"></div>
      </el-col>
      <el-col :span="6">
        <span class="SmallHead" style="padding-left: 10px;">广告消耗量排名</span>
        <el-table class="trendTable" :data="adTrendRank" style="width: 100%;" :show-header="false">
          <el-table-column prop="top" width="50">
            <template #default="{row}">
              <el-tag type="info" size="small" :effect="row.top <= 3 ? 'dark' : 'light'">
                {{ row.top }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="account_id" min-width="150" />
          <el-table-column prop="spent" min-width="150">
            <template #default="{row}"> $ {{ $filters.thousands(row.sum_spent) }} </template>
          </el-table-column>
          <template #empty>
            <img src="@/assets/empty.gif" alt="" style="width:200px;height:200px" />
          </template>
        </el-table>
      </el-col>
    </el-row>
  </el-card>

  <el-row :gutter="10" id="thirdRow">
    <!-- 账户成效分析 -->
    <el-col :span="12">
      <el-card class="box-card orderCard" style="min-height:530px; margin-bottom: 0px">
        <template #header>
          <div class="card-header">
            <span class="SubHead">账户成效分析</span>
          </div>
        </template>
        <div class="roasList">
          <p class="Text cut-p">转化率</p>
          <p class="cut-p">
            <span class="MainHead" style="margin-right: 30px;">{{ roas1Num }}</span>
            <span class="HelpText">
              周同比 {{ roas1Ratio }}<i v-show="cutROASType != 0">%</i>
              <i class="el-icon-top" v-if="cutROASType == 1"></i>
              <i class="el-icon-bottom" v-else-if="cutROASType == 2"></i>
              <i v-else></i>
            </span>
          </p>
          <!-- ROAS表单 -->
          <div id="roasTT" style="width: 100%;height:100px;"></div>
          <el-table
            ref="roasListRef"
            :data="roasList"
            :row-key="getRoiId"
            style="width: 100%"
            :header-cell-style="{
              background: 'var(--el-table-row-hover-background-color)'
            }"
          >
            <el-table-column property="top" label="排名" width="100px" />
            <el-table-column property="account_id" label="广告ID" />
            <el-table-column property="sum_roas" label="转化率" sortable />
            <el-table-column label="周涨幅">
              <template #default="{row}">
                {{ row.contrast }}<i v-show="row.type != 0">%</i>
                <i class="el-icon-top" v-if="row.type == 1"></i>
                <i class="el-icon-bottom" v-else-if="row.type == 2"></i>
                <i v-else></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </el-col>

    <!-- 广告投放地区数据 -->
    <el-col :span="12">
      <el-card class="box-card areaCard" style="min-height: 500px; margin-bottom: 0px">
        <template #header>
          <div class="card-header">
            <span class="SubHead">广告投放地区数据</span>
            <el-date-picker
              v-model="pieTimeRange"
              type="daterange"
              size="small"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="X"
              unlink-panels
              :shortcuts="shortcuts"
              :disabled-date="disabledDate"
              @calendar-change="calendarChange"
              @blur="changePieChart"
            ></el-date-picker>
          </div>
        </template>
        <!-- area表单 -->
        <el-row v-if="this.pieDataList !== ''">
          <el-col :span="17">
            <div id="areaTT" style="width: 100%;height:393px;"></div>
          </el-col>
          <el-col :span="7" style="display: flex;flex-direction: column;justify-content: center;">
            <ul class="pieData">
              <li class="Text" v-for="(item, index) in pieDataList" :key="index">
                <span>{{ item.percent }}%</span>
                <span>${{ $filters.thousands(item.amount) }}</span>
              </li>
            </ul>
          </el-col>
        </el-row>
        <el-empty :image-size="300" v-else>
          <template #image>
            <img src="@/assets/empty.gif"/>
          </template>
        </el-empty>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import * as echarts from 'echarts'
import _ from 'lodash'
import $ from 'jquery'
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
export default {
  name: 'TTDataPanel',
  data() {
    return {
      driverPanel: null,
      intro: '',
      // 昨日数据表1
      expendTotal: 0,
      weekType: '',
      dayType: '',
      weekRatio: '',
      dayRatio: '',
      expendDaily: 0,

      // CPM表(昨日数据表2)
      CPMNum: 0,
      ClicksDaily: 0,
      result1: {},
      option1: {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          show: false
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: [
          {
            data: [],
            type: 'line',
            areaStyle: {},
            color: ['#7749fc']
          }
        ],
        grid: {
          top: '5px',
          left: '5px',
          right: '5px',
          bottom: '5px'
        }
      },

      // 昨日数据表3
      CTRNum: 0,
      CTRWeekType: '',
      CTRDayType: '',
      CTRWeekRatio: 0,
      CTRDayRatio: 0,

      // ROAS表(昨日数据表4)
      ROASNum: 0,
      ROASType: 1,
      ROASAverage: 0,
      result2: {},
      option2: {
        tooltip: {
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          show: false
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            data: [],
            type: 'bar',
            color: ['#2b82ff']
          }
        ],
        grid: {
          top: '5px',
          bottom: '5px'
        }
      },
      adTime: '1',
      trendTimeRange: '',
      startDate: null,
      shortcuts: [
        {
          text: '昨天',
          value: () => {
            const start = new Date()
            const end = new Date()
            start.setTime(start.getTime() - 8.64e7)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
          }
        },
        {
          text: '近7天',
          value: () => {
            const start = new Date()
            const end = new Date()
            start.setTime(start.getTime() - 8.64e7 * 7)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
          }
        },
        {
          text: '近30天',
          value: () => {
            const start = new Date()
            const end = new Date()
            start.setTime(start.getTime() - 8.64e7 * 30)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
          }
        },
        {
          text: '近90天',
          value: () => {
            const start = new Date()
            const end = new Date()
            start.setTime(start.getTime() - 8.64e7 * 90)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
          }
        }
      ],
      // 账户消耗表
      result3: {},
      option3: {
        tooltip: {
          trigger: 'axis'
        },
        title: {
          text: '账户消耗',
          textStyle: {
            color: '#595959',
            fontSize: 14,
            fontWeight: 600
          },
          left: 10
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'line',
            color: ['#2b82ff']
          }
        ],
        grid: {
          top: '60px',
          left: '70px',
          right: '40px',
          bottom: '40px'
        }
      },
      // 账户消耗趋势排名
      adTrendRank: [],
      // 切换表单按钮
      cutROASType: 1,
      orderNum: 0,
      ordeRatio: 0,
      roas1Num: 0,
      roas1Ratio: 0,
      result5: {},
      option5: {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          show: false
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: [
          {
            data: [],
            type: 'line',
            areaStyle: {},
            color: ['#67C23A']
          }
        ],
        grid: {
          // 让图表占满容器
          top: '0px',
          left: '5px',
          right: '5px',
          bottom: '0px'
        }
      },
      roasList: [],
      // 投放地区数据
      pieTimeRange: '',
      result6: {},
      option6: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          left: '75%'
        },
        color: ['#2b82ff', '#00f1a2', '#ffb900', '#F56C6C', '#7749fc', '#73C0DE'],
        series: [
          {
            name: '投放地区',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['40%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 4
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      },
      pieDataList: []
    }
  },
  created() {
    if (this.trendTimeRange == '') {
      this.trendTimeRange = this.getThirtyDays()
    }
    if (this.pieTimeRange == '') {
      this.pieTimeRange = this.getSevenDays()
    }
  },
  mounted() {
    // this.getEchartsData()
    this.$bus.on('getTTEchartsData', this.getEchartsData)
  },
  beforeUnmount() {
    if (this.driverPanel != null) {
      this.driverPanel.reset()
    }
  },
  methods: {
    async getEchartsData() {
      // document.getElementById('CPMTT').removeAttribute('_echarts_instance_')
      // document.getElementById('ROASTT').removeAttribute('_echarts_instance_')
      // var cpmChart = echarts.init(document.getElementById('CPMTT'))
      // var roasChart = echarts.init(document.getElementById('ROASTT'))

      const { data: res } = await this.$http.post('cloudtt/dashboard')
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.intro = res.data.intro
      // 昨日数据表1
      this.expendTotal = res.data.yesterday_spent
      this.weekType = res.data.week_spent_type
      this.weekRatio = res.data.week_on_week_spent
      this.dayType = res.data.day_spent_type
      this.dayRatio = res.data.day_to_day_spent
      this.expendDaily = res.data.day_avg_spent
      // 昨日数据表2
      this.CPMNum = res.data.yesterday_cpm
      this.ClicksDaily = res.data.day_avg_click
      // 昨日数据表3
      this.CTRNum = res.data.yesterday_ctr
      this.CTRWeekType = res.data.week_ctr_type
      this.CTRWeekRatio = res.data.week_on_week_ctr
      this.CTRDayType = res.data.day_ctr_type
      this.CTRDayRatio = res.data.day_to_day_ctr
      // 昨日数据表4
      this.ROASNum = res.data.yesterday_roas
      this.ROASType = res.data.avg_roas_type
      this.ROASAverage = res.data.avg_roas
      // cost图表
      this.adTrendRank = res.data.account_spent
      this.result1 = res.data.week_cpm
      this.result2 = res.data.week_roas
      // const result1 = _.merge(res.data.week_cpm, this.option1)
      // const result2 = _.merge(res.data.week_roas, this.option2)
      this.result3 = res.data.spent
      this.result5 = res.data.week_roas
      // 账户成效分析-rosa
      this.roas1Num = res.data.sum_roas
      this.roas1Ratio = res.data.week_on_week_roas
      this.cutROASType = res.data.week_roas_type
      this.roasList = res.data.roas
      // 广告投放地区数据-area
      this.result6 = res.data.area_series
      this.pieDataList = res.data.pieData

      // cpmChart.setOption(result1)
      // roasChart.setOption(result2)

      this.initCpmChart()
      this.initCVRChart()
      this.initCostChart()
      this.initRoasChart()
      if (res.data.pieData !== '') {
        this.initAreaChart()
      }

      // 浏览器窗口自适应
      var _this = this
      window.onresize = () => {
        // cpmChart.resize()
        // roasChart.resize()
        _this.cpmChart.resize()
        _this.cvrChart.resize()
        _this.costChart.resize()
        _this.roasChart1.resize()
        
        if (_this.roasChart1 != undefined) {
          _this.areaChart.resize()
        }
     
      }
    },
    AddScroll() {
      var top = $('.el-main').scrollTop()
      $('.el-main').on('scroll.unable', function(e) {
        $('.el-main').scrollTop(top)
      })
    },
    RemoveScroll() {
      $('.el-main').unbind('scroll.unable')
    },
    // 新手引导
    panelGuide() {
      this.$nextTick(() => {
        this.driverPanel = new Driver({
          allowClose: false,
          opacity: 0.5,
          nextBtnText: '下一步',
          closeBtnText: '跳过',
          doneBtnText: '完成',
          onReset: () => {
            this.RemoveScroll()
            this.$http.post('user/set_intro', {
              type: 'dashboard'
            })
          },
          onHighlightStarted: () => {
            this.$nextTick(() => {
              this.AddScroll()
            })
          },
          onNext: () => {
            this.RemoveScroll()
          }
        })

        this.driverPanel.defineSteps([
          {
            element: '#firstRow',
            popover: {
              className: 'first-step-popover-class',
              title: '新手指引(1/3)',
              description:
                '这里展示的是您所有广告数据的汇总，您可以在这里快速的查看昨日的广告运行情况，点击叹号可查看每个指标的定义及计算公式。',
              position: 'bottom-right'
            }
          },
          {
            element: '#secondRow',
            popover: {
              title: '新手指引(2/3)',
              description: '在这里查看您的广告账户消耗的趋势折线图，以及广告账户的消耗排名。',
              position: 'top-right'
            }
          },
          {
            element: '#thirdRow',
            popover: {
              title: '新手指引(3/3)',
              description: '在这里查看您的广告账户的成效分析。',
              position: 'top-right',
              closeBtnText: '',
              doneBtnText: '完成'
            }
          }
        ])
        // Start the introduction
        this.driverPanel.start()
      })
    },
    initCpmChart() {
      document.getElementById('CPMTT').removeAttribute('_echarts_instance_')
      this.cpmChart = echarts.init(document.getElementById('CPMTT'))
      this.cpmChart.setOption(_.merge(this.result1, this.option1))
    },
    initCVRChart() {
      document.getElementById('ROASTT').removeAttribute('_echarts_instance_')
      this.cvrChart = echarts.init(document.getElementById('ROASTT'))
      this.cvrChart.setOption(_.merge(this.result2, this.option2))
    },
    initCostChart() {
      document.getElementById('costTT').removeAttribute('_echarts_instance_')
      this.costChart = echarts.init(document.getElementById('costTT'))
      this.costChart.setOption(_.merge(this.result3, this.option3))
    },
    initRoasChart() {
      document.getElementById('roasTT').removeAttribute('_echarts_instance_')
      this.roasChart1 = echarts.init(document.getElementById('roasTT'))
      this.roasChart1.setOption(_.merge(this.result5, this.option5))
    },
    initAreaChart() {
      document.getElementById('areaTT').removeAttribute('_echarts_instance_')
      this.areaChart = echarts.init(document.getElementById('areaTT'))
      // this.areaChart.setOption(this.option6)
      this.areaChart.setOption(_.merge(this.result6, this.option6))
    },
    // 默认显示近30天时间范围
    getThirtyDays() {
      const start = Math.round((new Date().getTime() - 8.64e7 * 30) / 1000)
      const end = Math.round((new Date().getTime() - 8.64e7) / 1000)
      return [start, end]
    },
    // 默认显示近7天时间范围
    getSevenDays() {
      const start = Math.round((new Date().getTime() - 8.64e7 * 7) / 1000)
      const end = Math.round((new Date().getTime() - 8.64e7) / 1000)
      return [start, end]
    },
    // 广告账户趋势选择时间范围
    goTrendDetail() {},
    calendarChange(dates) {
      // 记录选择的开始日期，方便后面根据开始日期限定结束日期
      if (dates !== null && dates.length > 0) {
        this.startDate = dates[0]
      }
    },
    // 日期选择器禁止选择的条件
    disabledDate(time) {
      // 8.64e7 等于 3600 * 1000 * 24
      if (this.startDate !== null) {
        return (
          time.getTime() > Date.now() - 8.64e7 ||
          time.getTime() < 1640966400 * 1000 ||
          time.getTime() > this.startDate.getTime() + 180 * 8.64e7 ||
          time.getTime() < this.startDate.getTime() - 180 * 8.64e7
        )
      } else {
        return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now() - 8.64e7
      }
    },
    // table需设置row-key
    getOrderId(item) {
      return item.account_id
    },
    getRoiId(item) {
      return item.account_id
    },
    // 广告账户趋势图表的时间选择
    async changeTrendChart() {
      if (this.trendTimeRange == null) {
        return this.$message.warning('请选择时间范围')
      }
      const { data: res } = await this.$http.post('cloudtt/get_dashboard', {
        spent_type: this.adTime,
        spent_time: this.trendTimeRange,
        type: 1
      })

      this.costChart.setOption(_.merge(res.data.spent, this.option3))
      console.log(res.data.account_spent);
      this.adTrendRank = res.data.account_spent
    },
    // 广告投放地区数据图表的时间选择
    async changePieChart() {
      if (this.pieTimeRange == null) {
        return this.$message.warning('请选择时间范围')
      }
      const { data: res } = await this.$http.post('cloudtt/get_dashboard', {
        area_time: this.pieTimeRange,
        type: 4
      })
      if (res.code == 100) {
        return this.$message.warning(`${res.msg}`)
      }
      this.areaChart.setOption(_.merge(res.data.area_series, this.option6))
      this.pieDataList = res.data.pieData
    }
  }
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.box-card {
  margin-bottom: 20px;
}
.card-header,
.never-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.neverCard {
  height: 230px;
  overflow: inherit;
  :deep(.el-card__header) {
    border-bottom: none;
  }
}
i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}
p.never-p,
p.cut-p {
  margin: 10px 0;
}
p.never-p {
  margin-top: 12px;
}
p.never-bp {
  margin: 0;
}
.never-divider {
  margin: 10px 0;
}
.trendDetail {
  margin-left: 10px;
}
.trendData,
.oderList,
.roasList {
  .el-table {
    :deep(tr) {
      height: auto;
    }
    :deep(.el-table__cell) {
      padding: 8px 0;
    }
    .el-tag {
      border-radius: 50%;
    }
  }
}
.el-icon-top,
.el-icon-caret-top {
  color: #00f1a2;
}
.el-icon-bottom,
.el-icon-caret-bottom {
  color: #f56c6c;
}
p.cut-p {
  display: flex;
  align-items: center;
}
.pieData {
  width: 100%;
  margin-top: 10px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-left: 1px solid #d9d9d9;
    padding-left: 8%;
    span {
      width: 50%;
    }
  }
}
.progressRange {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reportDetail {
  font-size: 14px;
  color: #2b82ff;
}
.orderCard,
.areaCard {
  :deep(.el-card__body) {
    height: 420px;
  }
}
</style>
